import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "index" */ "@/views/Home")
  },
  {
    path: "/solucoes/todas/",
    name: "Solucoes",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes.vue")
  },

  {
    path: "/solucoes/quartz/",
    redirect: "/solucoes/quartz-servico-de-suporte-a-sistemas/"
  },
  {
    path: "/solucoes/quartz-servico-de-suporte-a-sistemas/",
    name: "Quartz",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes/Quartz.vue")
  },

  {
    path: "/solucoes/opal/",
    redirect: "/solucoes/opal-gestao-de-estoques/"
  },
  {
    path: "/solucoes/opal-gestao-de-estoques/",
    name: "Opal",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes/Opal.vue")
  },

  {
    path: "/solucoes/jade/",
    redirect: "/solucoes/jade-sistema-gestao-ao-vivo/"
  },
  {
    path: "/solucoes/jade-sistema-gestao-ao-vivo/",
    name: "Jade",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes/Jade.vue")
  },

  {
    path: "/solucoes/gold/",
    redirect: "/solucoes/gold-construir-loja-online-com-nosso-omnichannel/"
  },
  {
    path: "/solucoes/gold-construir-loja-online-com-nosso-omnichannel/",
    name: "Gold",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes/Gold.vue")
  },

  {
    path: "/solucoes/diamond-por-que-devo-investir-em-um-marketplace/",
    name: "Diamond",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes/Diamond.vue")
  },

  {
    path: "/solucoes/ametista/",
    redirect: "/solucoes/ametista-software-gestao-educacional/"
  },
  {
    path: "/solucoes/ametista-software-gestao-educacional/",
    name: "Ametista",
    component: () =>
      import(
        /*  webpackChunkName: "solucoes" */ "@/views/Solucoes/Ametista.vue"
      )
  },

  {
    path: "/solucoes/garnet/",
    redirect: "/solucoes/garnet-como-aplicar-iot/"
  },
  {
    path: "/solucoes/garnet-como-aplicar-iot/",
    name: "Garnet",
    component: () =>
      import(/* webpackChunkName: "solucoes" */ "@/views/Solucoes/Garnet.vue")
  },
  /*  {
     path: "/segmentos/todos/",
     name: "Segmentos",
     component: () =>
       import(webpackChunkName: "segmentos" "@/views/Segmentos.vue")
   },
 
   {
     path: "/segmentos/agricola/",
     redirect: "/segmentos/sistemas-agricolas-de-gestao/"
   },
   {
     path: "/segmentos/sistemas-agricolas-de-gestao/",
     name: "Agricola",
     component: () =>
       import(
         webpackChunkName: "segmentos" "@/views/Segmentos/Agricola.vue"
       )
   },
 
   {
     path: "/segmentos/atacado/",
     redirect: "/segmentos/sistema-atacado-e-distribuicao/"
   },
   {
     path: "/segmentos/sistema-atacado-e-distribuicao/",
     name: "Atacado",
     component: () =>
       import(
         webpackChunkName: "segmentos" "@/views/Segmentos/Atacado.vue"
       )
   },
 
   {
     path: "/segmentos/manufatura/",
     redirect: "/segmentos/sistema-gestao-manufatura/"
   },
   {
     path: "/segmentos/sistema-gestao-manufatura/",
     name: "Manufatura",
     component: () =>
       import(
         webpackChunkName: "segmentos" "@/views/Segmentos/Manufatura.vue"
       )
   },
   {
     path: "/segmentos/sistema-gestao-educacional/",
     name: "Educacao",
     component: () =>
       import(
         webpackChunkName: "segmentos" "@/views/Segmentos/Educacao.vue"
       )
   }, */

  {
    path: "/shinier/sobre/",
    redirect: "/shinier/sobre-a-shinier-nossa-missao-e-historia/"
  },
  {
    path: "/shinier/sobre-a-shinier-nossa-missao-e-historia/",
    name: "Shinier",
    component: () =>
      import(/* webpackChunkName: "shinier" */ "@/views/Shinier.vue")
  },

  {
    path: "/shinier/sobre/",
    redirect: "/shinier/sobre-a-shinier-nossa-missao-e-historia/"
  },

  {
    path: "/fabrica-de-software/",
    redirect: "/shinier/principais-duvidas-fabrica-de-software/"
  },

  //essa rota pega os slugs dos cards da Home
  /* {
    path: "/shinier/principais-duvidas-fabrica-de-software/:route",
    name: "fabrica-slug",
    component: () =>
      import(webpackChunkName: "shinier" "@/views/Fabrica.vue")
  },
 */
  //essa rota pegaria as categorias de dúvidas
  /*   {
      path: "/shinier/principais-duvidas-fabrica-de-software/:category",
      name: "category",
      component: () =>
        import(webpackChunkName: "shinier" "@/views/Fabrica.vue")
    }, */

  {
    path: "/shinier/principais-duvidas-fabrica-de-software/",
    name: "Fabrica",
    component: () =>
      import(/* webpackChunkName: "shinier" */ "@/views/Fabrica.vue")
  },

  {
    path: "/artigos/",
    redirect: "/aceleracao/"
  },

  {
    path: "/aceleracao/",
    name: "Artigos",
    component: () =>
      import(/* webpackChunkName: "aceleracao" */ "@/views/Aceleracao")
  },

  {
    path: "/artigos/como-crescer-meu-negocio-rapido/",
    redirect: "/aceleracao/como-crescer-meu-negocio-rapido/"
  },
  {
    path: "/aceleracao/como-crescer-meu-negocio-rapido/",
    name: "Artigo01",
    component: () =>
      import(
        /* webpackChunkName: "aceleracao" */ "@/views/Artigos/Artigo01.vue"
      )
  },

  {
    path: "/artigos/empreendedorismo-e-meio-ambiente/",
    redirect: "/aceleracao/empreendedorismo-e-meio-ambiente/"
  },
  {
    path: "/aceleracao/empreendedorismo-e-meio-ambiente/",
    name: "Artigo02",
    component: () =>
      import(
        /* webpackChunkName: "aceleracao" */ "@/views/Artigos/Artigo02.vue"
      )
  },
  {
    path: "/artigos/os-desafios-de-criar-um-aplicativo-universal/",
    redirect: "/aceleracao/os-desafios-de-criar-um-aplicativo-universal/"
  },
  {
    path: "/aceleracao/os-desafios-de-criar-um-aplicativo-universal/",
    name: "Artigo03",
    component: () =>
      import(
        /* webpackChunkName: "aceleracao" */ "@/views/Artigos/Artigo03.vue"
      )
  },

  {
    path: "/duvidas/quanto-custa-desenvolver-um-aplicativo/",
    name: "Duvida01",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida01.vue"
      )
  },

  {
    path: "/duvidas/o-que-e-um-mockup/",
    redirect: "/duvidas/o-que-e-um-mockup-e-para-que-serve/"
  },
  {
    path: "/duvidas/o-que-e-um-mockup-e-para-que-serve/",
    name: "Duvida02",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida02.vue"
      )
  },

  {
    path: "/duvidas/quais-sao-as-etapas-desenvolvimento-software/",
    name: "Duvida03",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida03.vue"
      )
  },
  {
    path: "/duvidas/tipos-de-teste-de-software-e-por-que-automatiza-los/",
    name: "Duvida04",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida04.vue"
      )
  },
  {
    path: "/duvidas/o-que-e-e-como-funciona-uma-api/",
    name: "Duvida05",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida05.vue"
      )
  },

  {
    path: "/poc-o-que-e-e-porque-e-importante/",
    redirect: "/duvidas/proof-of-concept-poc-o-que-e-e-porque-e-importante/"
  },
  {
    path: "/duvidas/proof-of-concept-poc-o-que-e-e-porque-e-importante/",
    name: "Duvida06",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida06.vue"
      )
  },

  {
    path: "/duvidas/como-proteger-meu-software/",
    redirect:
      "/duvidas/como-proteger-meu-software-patente-e-propriedade-intelectual/"
  },
  {
    path:
      "/duvidas/como-proteger-meu-software-patente-e-propriedade-intelectual/",
    name: "Duvida07",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida07.vue"
      )
  },

  {
    path: "/duvidas/como-criar-um-mvp/",
    redirect: "/duvidas/como-criar-um-mvp-passo-a-passo/"
  },
  {
    path: "/duvidas/como-criar-um-mvp-passo-a-passo/",
    name: "Duvida08",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida08.vue"
      )
  },
  {
    path: "/duvidas/quanto-custa-para-desenvolver-um-site/",
    name: "Duvida09",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida09.vue"
      )
  },
  {
    path: "/duvidas/quanto-custa-para-desenvolver-um-software/",
    name: "Duvida10",
    component: () =>
      import(
        /* webpackChunkName: "duvidas" */ "@/views/PrincipaisDuvidas/Duvida10.vue"
      )
  },
  {
    path: "/shinier/politicadeprivacidade/",
    name: "Politica",
    component: () =>
      import(/* webpackChunkName: "shinier" */ "@/views/Shinier/Politica.vue")
  },
  {
    path: "/shinier/contato",
    name: "Contato",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/Shinier/Contato.vue")
  },
  {
    path: "/area-restrita-clientes/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "index" */ "@/views/Login.vue")
  },
  {
    path: "/messages",
    name: "Messages",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/Messages.vue")
  },

  { path: "*", redirect: "/not-found" },
  {
    path: "/not-found",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "@/views/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
});

export default router;
