export default {
  namespaced: true,
  state: {
    solucoes: [
      {
        id: 1,
        titleImage: "gestao-image gold",
        title: "Gold - Omnichannel",
        icon: "icon icon-gold-color-30",
        desc:
          "Com um Gold-Omnichannel sua loja estará online 24 horas para seus clientes e integrado com os principais players do mercado (mercado livre...",
        route: "Gold"
      },
      {
        id: 2,
        titleImage: "gestao-image opal",
        title: "Opal - WMS",
        icon: "icon icon-opal-color-30",
        desc:
          "Um sistema do tipo WMS (Warehouse Management System) é um software destinado ao recolhimento de informações importantes ...",
        route: "Opal"
      },

      {
        id: 3,
        titleImage: "gestao-image jade",
        title: "Jade - LiMS",
        icon: "icon icon-jade-color-30",
        desc:
          "O Jade-LiMS (Live Management System) vem consolidar o monitoramento ao vivo dos principais componentes do seu processo, e ativos ...",
        route: "Jade"
      },
       {
        id: 4,
        titleImage: "gestao-image diamond",
        title: "Diamond - marketplaces",
        icon: "icon icon-diamond-color-30",
        desc:
          "Customizamos uma plataforma para você, com gestão de acesso, multi nível de usuários, auto cadastro, supervisionado ou integrado, e muito mais",
        route: "Diamond"
      },
      {
        id: 5,
        titleImage: "gestao-image quartz",
        title: "Quartz - AMS",
        icon: "icon icon-quartz-color-30",
        desc:
          "Trata-se de um serviço de suporte a sistemas. (R&D - Research e Development, P&D - Pesquisa e Desenvolvimento)...",
        route: "Quartz"
      },
      {
        id: 6,
        titleImage: "gestao-image garnet",
        title: "Garnet - Beacons",
        icon: "icon icon-garnet-color-30",
        desc:
          "É um módulo de comunicação de IoT's e algoritmos de data science, afim de coletar dados críticos para análise da curva de produção...",
        route: "Garnet"
      }
    ],
    impacto: [
      {
        ambiental: `São os indicadores que têm impacto ambiental, 
      causados pelo dia a dia da empresa, 
      ou diretamente pela atuação da empresa. Muitos negócios tem grande geração de documentos impressos e que 
      podem ser digitalizados, ou empresas do setor agro.`
      },
      {
        economico: `São os indicadores que têm impacto direto nos fatores 
        econômicos da sua empresa. Entre esse indicadores os mais visados são: 
        escalabilidade,  equilíbrio, retorno de investimento e muitos outros.  
        Lembre-se que o investimento é o gasto com visão de futuro.`
      },
      {
        social: `São os indicadores que têm impacto nas pessoas, 
        seja diretamente ou indiretamente. Uma empresa tem a responsabilidade 
        direta sobre seus funcionários e clientes. 
        Mas também tem a responsabilidade com o futuro da sociedade, 
        esse sim é um bom investimento.`
      }
    ]
  }
};
