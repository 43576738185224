<template>
  <div id="app">
    <Sidebar v-if="$route.name !== 'NotFound'" />
    <router-view />
    <Footer />
    <FooterContato />
  </div>
</template>
<script>
export default {
  components: {
    Sidebar: () => import("@/components/Sidebar"),
    Footer: () => import("@/components/Footers/Footer"),
    FooterContato: () => import("@/components/Footers/FooterContato")
  }
};
</script>
<style lang="scss">
@import "assets/css/style.css";
@import "assets/css/style.scss";
@import "assets/glyphter-font/css/shinier.css";
@import "assets/css/colors.css";
@import "assets/css/icons.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<!-- <url><loc>https://shinier.com.br/solucoes/ametista-software-gestao-educacional/</loc><lastmod>2022-07-22</lastmod></url> -->
