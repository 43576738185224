import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Router from "vue-router";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import store from "./store";
import api from "./services";
import VueMeta from "vue-meta";
import VueTagManager from "vue-tag-manager";
import VueAnalytics from "vue-ua";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTimes, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { VueMaskDirective } from "v-mask";

library.add(faCheck, faTimes, faArrowUp);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, { observer: true });

Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;

if (store.state.token !== undefined) {
  api.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;
}

Router.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject);
  window.open(href, "_blank");
};

Vue.use(Buefy);
Vue.use(VueMeta);
Vue.use(VueTagManager, {
  gtmId: "GTM-PBD23LC"
});

Vue.use(VueAnalytics, {
  appName: "shinier",
  appVersion: "0.1.0",
  trackingId: "UA-192089278-1",
  vueRouter: router,
  trackPage: true
});


// previnindo abertura das devtools
/* window.addEventListener("keydown", ev => {
  switch (true) { */
    /* Previne F12 */
/*     case ev.keyCode === 123:
      ev.preventDefault();
      return false; */

    /* Previne Ctrl + Shift +  */
/*     case ev.ctrlKey && ev.shiftKey && event.keyCode == 74:
      ev.preventDefault();
      return false; */

    /* Previne Ctrl + U */
/*     case ev.ctrlKey && ev.keyCode == 85:
      ev.preventDefault();
      return false; */

    /* Previne CMD + Alt + C (Chrome) ou  MD + Shift + C (Chrome)*/
/*     case ev.ctrlKey && ev.altKey && ev.keyCode == 67:
      ev.preventDefault();
      return false; */

    /* Previne CMD + Shift + C (Chrome)*/
/*     case ev.ctrlKey && ev.shiftKey && ev.keyCode == 67:
      ev.preventDefault();
      return false; */

    /* Previne Ctrl + Shift + I (Chrome, Firefox, Safari, Edge)*/
/*     case ev.ctrlKey && ev.shiftKey && ev.keyCode == 73:
      ev.preventDefault();
      return false;
 */
    /* Previne CMD + Alt + U*/
/*     case ev.ctrlKey && ev.altKey && ev.keyCode == 85:
      ev.preventDefault();
      return false;
  }
}); */

/* Previne clique direito do mouse */
/* window.addEventListener("contextmenu", ev => {
  ev.preventDefault();
  return false;
}); */

// DevTools Opened Script
/* function DevToolsOpened() {
  //impedindo console.log()
  var __log = console;
  __log = function () { };

  //disable alert, confirm e prompt
  window.alert = function () { };
  window.confirm = function () { };
  window.prompt = function () { };
  window.console.log = function () { }
  return __log;
}
 */
// Detect DevTools (Chrome/Edge)
// https://stackoverflow.com/a/67148898/9498503 (SeongJun)
/* var devtools = function () { };
devtools.toString = function () {
  DevToolsOpened();
  return false;
}

setInterval(() => {
  if (console.clear) {
    console.clear();
    DevToolsOpened()
  }
}, 500);
 */
// Detect DevTools (FireFox)
/* if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
  // Detect Resize (Chrome/Firefox/Edge Works) but (Triggers on Zoom In Chrome and Zoom Out FireFox)
  window.onresize = function () {
    if ((window.outerHeight - window.innerHeight) > 100 || (window.outerWidth - window.innerWidth) > 100) {
      DevToolsOpened();
    }
  }
} */

// Detect Fire Bug
/* if (window.console && window.console.firebug || console.assert(1) === '_firebugIgnore') {
  DevToolsOpened();
}; */

new Vue({
  router: router,
  store,
  render: h => h(App)
}).$mount("#app");
