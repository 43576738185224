export default {
  /** module que gerencia os cards dos artigos (geral) */
  namespaced: true,
  state: {
    cards: [
      {
        id: 1,
        relevance: 1,
        imgIcon: "rgb(248, 80, 37)",
        imgCard: "imp-economico-card",
        title: "ESCALABILIDADE: ",
        subtitle: "Como crescer meu negócio rápido",
        desc: `A Escalabilidade é um fator determinante para o futuro das empresas em fase inicial e StartUps,
        as quais dependem desse fator para crescerem.
        Apesar de sua grande importância, o estudo da Escalabilidade ainda é pouco
        explorado no mercado e no meio acadêmico no Brasil.
        O objetivo deste artigo é analisar como ela é percebida e aplicada atualmente por empreendedores,
        empresas em fase embrionária e por investidores de diversos âmbitos no cenário nacional
        e também a elaboração de um questionário estruturado para futura pesquisa de campo sobre escalabilidade
        de empresas.`,
        route: "Artigo01",
        keywords: "Negócios; Empreendedorismo; Maturidade; Startup.",
        author: " Marcius Leandro Junior, CEO SHINIER -",
        datePub: "20/07/2021",
        timePub: "",
        research: "Em que fase da vida sua empresa está?",
        link: "https://forms.gle/Zhqz9szuSbCaKrLHA"
      },
      {
        id: 2,
        relevance: 2,
        imgIcon: "rgb(0, 204, 179)",
        imgCard: "imp-ambiental-card",
        title: "REGENERAÇÃO: ",
        subtitle: "Empreendedorismo e Meio ambiente",
        desc: `O desafio de promover o desenvolvimento sustentável requer uma ação coletiva e intersetorial para diagnóstico de entraves e construção de um planejamento adequado. No campo internacional, compromissos políticos assumidos pelo Brasil, como a adesão à Agenda 2030 para o Desenvolvimento Sustentável das Nações Unidas e a promulgação do Acordo de Paris, exigem ações concretas que demandam fortes investimentos em infraestrutura. A contribuição brasileira para a proteção do clima global, aliada a outros investimentos climáticos, exigia cerca de US$ 1,3 trilhão entre 2016 e 2030, segundo estimativa da International Finance Corporation (IFC).`,
        route: "Artigo02",
        keywords: " Meio ambiente; regeneração; impacto positivo",
        author: " Marcius Leandro Junior, CEO SHINIER -",
        datePub: "10/08/2021",
        timePub: "",
        research: "",
        link: ""
      },
      {
        id: 3,
        relevance: 3,
        imgIcon: "rgb(86, 150, 225)",
        imgCard: "imp-social-card",
        title: "UNIVERSALIDADE: ",
        subtitle: "Os desafios de criar um aplicativo universal",
        desc: `No senso comum, a universalidade parece evidenciar os aspectos referentes ao uso e acesso dos espaços físicos. Entretanto, numa acepção mais ampla, é a condição de possibilidade para a transposição dos entraves que representam as barreiras para a efetiva participação de pessoas nos vários âmbitos da vida social. Pensar nesse conceito na hora de desenvolver um software e mais especificamente um aplicativo, é uma estratégia garantida de faturamento e fidelização, afinal 24% do índice demográfico inclui pessoas com algum grau de deficiência.`,
        route: "Artigo03",
        keywords: "Inclusão; Universalidade, Visibilidade",
        author: " Marcius Leandro Junior, CEO SHINIER -",
        datePub: "01/09/2021",
        timePub: "",
        research: "",
        link: ""
      }
    ]
  }
};
