export default {
  namespaced: true,
  /** Todos os paths de imagens que vão nas soluções, e nos cards da home
   *  aplicando lazy load para aprimorar a performance */
  state: {
    //soluções
    imagesGold: [
      {
        src: require("../../assets/images/gold-1.png"),
        alt:
          "Gráfico com o mapa do brasil e as porcentagens de consumidores por região"
      },
      {
        src: require("../../assets/images/gold-2.png"),
        alt:
          "Moça segurando um pacote com frutas em uma mão, e na outra mão um tablet mostrando seu pedido"
      },
      {
        src: require("../../assets/images/gold-3.png"),
        alt: "Cesta de supermercado pequena em cima de um teclado de notebook"
      },
      {
        src: require("../../assets/images/gold-4.png"),
        alt: "IoT"
      },
      {
        src: require("../../assets/images/nildo-adega.webp"),
        alt: "nildo adega"
      },
      {
        src: require("../../assets/images/lana-olieveira.png"),
        alt: "lana oliveira"
      }
    ],

    imagesDiamond: [
      {
        src: require("../../assets/images/online-market-place.png"),
        alt:
          "Gráfico com o mapa do brasil e as porcentagens de consumidores por região"
      },
      {
        src: require("../../assets/images/diamond-1.png"),
        alt:
          "Moça segurando um pacote com frutas em uma mão, e na outra mão um tablet mostrando seu pedido"
      },
      {
        src: require("../../assets/images/gold-2.png"),
        alt: "Cesta de supermercado pequena em cima de um teclado de notebook"
      },
      {
        src: require("../../assets/images/diamond-2.png"),
        alt: "small"
      },
      {
        src: require("../../assets/images/small.webp"),
        alt: "small"
      },
      /*  {
         src: require("../../assets/images/nildo-adega.webp"),
         alt: "nildo adega"
       },
       {
         src: require("../../assets/images/lana-olieveira.png"),
         alt: "lana oliveira"
       } */
    ],
    imagesAmetista: [
      {
        src: require("../../assets/images/ametista4.png"),
        alt:
          "Uma criança com um notebook e um caderno em cima de um sofá, estudando em casa."
      },
      {
        src: require("../../assets/images/ametista2.png"),
        alt:
          "Três crianças, duas meninas e um menino, uma delas segurando um notebook, representando os benefícios de um software voltado para a educação"
      },
      {
        src: require("../../assets/images/ametista3.png"),
        alt:
          "Um homem segurando um cofrinho de porco sem dinheiro. Na mesa, várias contas a pagar e um par de óculos."
      },
      {
        src: require("../../assets/images/ametista1.png"),
        alt:
          "Uma maçã sobre uma pilha de livros em cima de uma mesa. Ao fundo, uma lousa escolar."
      },
      {
        src: require("../../assets/images/ametista5.png"),
        alt:
          "Uma mão segurando um celular. Na tela, um aplicativo de plataforma de estudo à distância"
      },
      {
        src: require("../../assets/images/plataform.png"),
        alt: "Pessoa sentada em frente ao notebook"
      },
      {
        src: require("../../assets/images/plataform.png"),
        alt: "Pessoa sentada em frente ao notebook"
      },
      {
        src: require("../../assets/images/virtualred.webp"),
        alt: "virtualred"
      }
    ],
    imagesOpal: [
      {
        src: require("../../assets/images/opal-1.png"),
        alt: "Etiquetas RFID e Beacons"
      },
      {
        src: require("../../assets/images/opal-2.png"),
        alt: "Mulher lendo TAGs"
      },
      {
        src: require("../../assets/images/opal-3.png"),
        alt: "Kanban"
      },
      {
        src: require("../../assets/images/opal-4.png"),
        alt: "Automatização do Processo"
      },
      {
        src:
          "https://www.sonda.com/ResourcePackages/Sonda/assets/images/logo-sonda.svg",
        alt: "Sonda Ativas"
      }
    ],
    imagesJade: [
      {
        src: require("../../assets/images/jade-1.png"),
        alt: "Infográfico sistema de Gestão ao Vivo - LiMS"
      },
      {
        src: require("../../assets/images/jade-2.png"),
        alt: "YML-LMS-EA"
      },
      {
        src: require("../../assets/images/jade-3.png"),
        alt: "Modelo de Forças de Porter"
      },
      {
        src: require("../../assets/images/jade-4.png"),
        alt: "IoT"
      },
      {
        src: require("../../assets/images/agro-1.jpg"),
        alt: "Gestão do Ativos"
      },
      {
        src: require("../../assets/images/agro-2.png"),
        alt: "GO UP Produtor Rural"
      },
      {
        src: require("../../assets/images/agro-3.png"),
        alt: "CRM"
      },
      {
        src: require("../../assets/images/agro-4.png"),
        alt: "HCM"
      },
      {
        src: require("../../assets/images/agro-5.png"),
        alt: "Frota de Caminhões"
      },
      {
        src: require("../../assets/images/agro-6.png"),
        alt: "Acesso e Segurança"
      },
      {
        src: require("../../assets/images/agro-7.png"),
        alt: "ERP"
      },
      {
        src: require("../../assets/images/acquanativa.webp"),
        alt: "acquanativa"
      }
    ],
    imagesQuartz: [
      {
        src: require("../../assets/images/quartz-1.png"),
        alt: "Equipe de Trabalho"
      },
      {
        src: require("../../assets/images/quartz-2.png"),
        alt: "Equipe de Trabalho"
      },
      {
        src: require("../../assets/images/quartz-3.png"),
        alt: "Service Desk"
      },
      {
        src: require("../../assets/images/quartz-4.png"),
        alt: "Equipe Satisfeita"
      },
      {
        src: require("../../assets/images/condinvest.png"),
        alt: "condinvest"
      }
    ],
    imagesGarnet: [
      {
        src: require("../../assets/images/garnet-1.png"),
        alt: "Staff"
      },
      {
        src: require("../../assets/images/factory-4.png"),
        alt: "Equipamentos"
      },
      {
        src: require("../../assets/images/factory-5.png"),
        alt: "Inventário"
      },
      {
        src: require("../../assets/images/garnet-4.png"),
        alt: "Tecnologia"
      },
      {
        src: require("../../assets/images/garnet-5.png"),
        alt: "Módulo Garnet"
      },
      {
        src: require("../../assets/images/coss-consulting.png"),
        alt: "coss consulting"
      }
    ],

    //segmentos
    imagesAgricola: [
      {
        src: require("../../assets/images/agro-1.jpg"),
        alt: "Gestão do Ativos"
      },
      {
        src: require("../../assets/images/agro-2.png"),
        alt: "GO UP Produtor Rural"
      },
      {
        src: require("../../assets/images/agro-3.png"),
        alt: "CRM"
      },
      {
        src: require("../../assets/images/agro-4.png"),
        alt: "HCM"
      },
      {
        src: require("../../assets/images/agro-5.png"),
        alt: "Frota de Caminhões"
      },
      {
        src: require("../../assets/images/agro-6.png"),
        alt: "Acesso e Segurança"
      },
      {
        src: require("../../assets/images/agro-7.png"),
        alt: "ERP"
      },
      {
        src: require("../../assets/images/acquanativa.webp"),
        alt: "acquanativa"
      }
    ],
    imagesAtacado: [
      {
        src: require("../../assets/images/opal-1.png"),
        alt: "Logistica"
      },
      {
        src: require("../../assets/images/agro-7.png"),
        alt: "ERP"
      },
      {
        src: require("../../assets/images/agro-4.png"),
        alt: "HCM"
      },
      {
        src: require("../../assets/images/agro-6.png"),
        alt: "Acesso e Segurança"
      },
      {
        src: require("../../assets/images/nildo-adega.webp"),
        alt: "nildo adega"
      },
      {
        src: require("../../assets/images/lana-olieveira.png"),
        alt: "lana oliveira"
      }
    ],
    imagesManufatura: [
      {
        src: require("../../assets/images/factory-1.png"),
        alt: "Alimentos"
      },
      {
        src: require("../../assets/images/factory-2.png"),
        alt: "Calçados"
      },
      {
        src: require("../../assets/images/factory-3.png"),
        alt: "Madeira e Móveis"
      },
      {
        src: require("../../assets/images/factory-4.png"),
        alt: "Metal Mecânico"
      },
      {
        src: require("../../assets/images/factory-5.png"),
        alt: "Madeira e Móveis"
      },
      {
        src: require("../../assets/images/coss-consulting.png"),
        alt: "coss consulting"
      }
    ],

    //logo parceiros
    imagesParceiros: [
      {
        src: require("../../assets/images/dnausp.svg"),
        alt: "DNA USP"
      },
      {
        src: require("../../assets/images/coss.png"),
        alt: "Coss"
      },
      {
        src: require("../../assets/images/aws.png"),
        alt: "Amazon"
      },
      {
        src: require("../../assets/images/sap.png"),
        alt: "SAP"
      },
      {
        src: require("../../assets/images/skyhub.png"),
        alt: "SkyHub"
      },
      {
        src: require("../../assets/images/mercado-livre.png"),
        alt: "Mercado Livre"
      },
      {
        src: require("../../assets/images/banco-do-brasil.png"),
        alt: "Banco do Brasil"
      }
    ],

    //card cases home
    imagesCases: [
      {
        src: require("../../assets/images/acquanativa.webp"),
        alt: "acquanativa"
      },
      {
        src: require("../../assets/images/small.webp"),
        alt: "small"
      },
      {
        src: require("../../assets/images/condinvest.png"),
        alt: "condinvest"
      },
      {
        src: require("../../assets/images/virtualred.webp"),
        alt: "virtualred"
      },
      /* aqui vão as imagens da seção "quem confia na shinier" */
      {
        src:
          "https://images.tcdn.com.br/img/img_prod/691716/1558355736_whatsapp_image_2019-05-17_at_13.29.08.jpeg",
        alt: "lana oliveira",
        href: "https://www.lanaoliveira.com.br/"
      },
      {
        src: "https://vulpee.com/img/logo.2e1d94a9.svg",
        alt: "vulpee"
      },
      {
        src: require("../../assets/images/logo-clean.png"),
        alt: "clean first time",
        href: "http://www.cleanfirsttime.com/"
      },
      {
        src:
          "https://kikadigitalorthodontics.com.br/wp-content/uploads/2021/01/logo-kika.png",
        alt: "kika ortodontia",
        href: "https://kikadigitalorthodontics.com.br"
      },
      {
        src: "https://app.xprexbox.com/img/logo.c74e4479.png",
        alt: "xprexbox",
        href: "https://app.xprexbox.com"
      },
      {
        src:
          "https://nildoadega.com.br/wp-content/uploads/2018/09/logo_vc_beer_00c100c31_1997.png",
        alt: "nildo adega",
        href: "https://nildoadega.com.br"
      }
    ],
    imageCaseCarousel: [
      /* aqui vão as imagens da seção "quem confia na shinier" */
      {
        src: require("../../assets/images/lana-oliveira-logo.png"),
        alt: "lana oliveira",
        href: "https://www.lanaoliveira.com.br/"
      },
      {
        src: "https://vulpee.com/img/logo.2e1d94a9.svg",
        alt: "vulpee"
      },
      {
        src: require("../../assets/images/logo-clean.png"),
        alt: "clean first time",
        href: "http://www.cleanfirsttime.com/"
      },
      {
        src:
          "https://kikadigitalorthodontics.com.br/wp-content/uploads/2021/01/logo-kika.png",
        alt: "kika ortodontia",
        href: "https://kikadigitalorthodontics.com.br"
      },
      {
        src: "https://app.xprexbox.com/img/logo.c74e4479.png",
        alt: "xprexbox",
        href: "https://app.xprexbox.com"
      },
      {
        src:
          "https://nildoadega.com.br/wp-content/uploads/2018/09/logo_vc_beer_00c100c31_1997.png",
        alt: "nildo adega",
        href: "https://nildoadega.com.br"
      },
      {
        src: require("../../assets/icons/small.svg"),
        alt: "small doações",
        href: "https://smalldoacoes.com.br/"
      },
      {
        src: require("../../assets/images/logo-virtualred.png"),
        alt: "virtual red",
        href: "https://app.virtualred.com.br/login"
      },
      {
        src:
          "https://condinvest.com.br/wp-content/themes/condinvest/images/logoCondinvest.png",
        alt: "condinvest",
        href: "https://condinvest.com.br/"
      },
      {
        src: "https://dashboard.businessindicator.com/images/logo.png",
        alt: "business indicator",
        href: "https://businessindicator.com/"
      },
      {
        src: require("../../assets/images/LOGO-ALCANCE.svg"),
        alt: "Agência Alcance",
        href: "https://lancesuamusica.alcanceagencia.com/"
      },
      {
        src: require("../../assets/images/planet-music.png"),
        alt: "Planet Music",
        href: "https://www.planetmusicexpress.com.br/"
      },
      {
        src:
          "https://www.sonda.com/ResourcePackages/Sonda/assets/images/logo-sonda.svg",
        alt: "Sonda Ativas",
        href: "https://www.sonda.com/pt"
      },
      {
        src: require("../../assets/images/acquanativa-removebg-preview.png"),
        alt: "acquanativa",
        href: "https://www.acquanativa.com.br/"
      },
      {
        src: require("../../assets/images/sin-implant-system-logo-topo 1.png"),
        alt: "S.I.N. IMPLANT SYSTEM",
        href: "https://www.sinimplantsystem.com.br/"
      }
    ]
  }
};
