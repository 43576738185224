export default {
  /** module que cuida das infos das principais dúvidas */
  namespaced: true,
  questions: [
    {
      title: "Quanto custa para desenvolver um aplicativo?",
      imgCard: "img-app",
      desc: `Os aplicativos mobile são uma solução para aproximar 
      as marcas do público e oferecer mais praticidade no dia a dia. 
      Não por acaso, a maioria das empresas tem ou já pensou em ter um app. 
      E para o projeto sair do papel, uma das primeiras questões a observar é o custo. 
      A verdade é que o preço pode variar muito de acordo com as necessidades do projeto e a sua complexidade.
      `,
      route: "Duvida01",
      category: "#execution"
    },
    {
      title: "O que é e para que serve um Mockup?",
      imgCard: "img-mockup",
      desc: `Um mockup é um design estático de uma página da Web ou aplicativo 
      que apresenta muitos de seus elementos finais de design, mas não é funcional. 
      Uma maquete não tão polida quanto uma página ativa e normalmente inclui alguns dados 
      de espaço reservado.`,
      route: "Duvida02",
      category: "#prototype"
    },
    {
      title: "Quais são as etapas do desenvolvimento de um software?",
      imgCard: "img-etapas",
      desc: `A organização em etapas bem definidas do processo Desenvolvimento de Software 
      facilita o controle e iteração do processo, proporcionando uma maior agilidade, 
      resposta às mudanças ao longo do processo e, principalmente, qualidade do software.`,
      route: "Duvida03",
      category: "#execution"
    },
    {
      title: "Quais os tipos de testes de software e por que automatizá-los?",
      imgCard: "img-testes",
      desc: `Teste de software é um processo no desenvolvimento do programa, 
      podendo ser feito pelos próprios desenvolvedores, e profissionais especializados, 
      ou pelos clientes beta. O procedimento tem como objetivo antecipar, corrigir falhas 
      e bugs que apareceriam para o usuário final. 
      `,
      route: "Duvida04",
      category: "#execution"
    },
    {
      title: "O que é e como funciona uma API?",
      imgCard: "img-api",
      desc: `API é um acrônimo para Application Programming Interface Trata-se 
      de um conjunto de rotinas e padrões que facilitam a comunicação e troca de 
      informações entre sistemas.
      Em resumo é um “conector” pré-estabelecido para um sistema.`,
      route: "Duvida05",
      category: ""
    },

    {
      title: "Proof of concept (PoC): O que é e porque é importante?",
      imgCard: "img-poc",
      desc: `A Prova de Conceito é utilizada para que se verifique se o produto 
      funciona em um nível básico, sem ainda pensar em como ele vai performar de fato no mercado. 
      A ideia é remover erros da arquitetura do software e garantir que ele vá rodar em boa forma.
      `,
      route: "Duvida06",
      category: ""
    },
    {
      title: "Como proteger meu software?",
      imgCard: "img-protecao",
      desc: `A patente de um software é algo complexo, 
      apesar de existir o processo no INPI, pequenas alterações irão quebrar a patente. 
      Por isso o processo visa a proteção do designer da interface e marca. 
      Para proteger seu negócio estude as 5 forças de Michael Porter.`,
      route: "Duvida07",
      category: ""
    },

    {
      title: "Como criar um MVP?",
      imgCard: "img-mvp",
      desc: `Um MVP é uma versão mínima do produto, 
      apenas com as funcionalidades necessárias para 
      que ele cumpra a função para a qual foi planejado. 
      É util para investir o mínimo necessário para se avaliar 
      o modelo do negócio, antes de se investir em automatizações.`,
      route: "Duvida08",
      category: ""
    },

    {
      title: "Quanto custa para desenvolver um site?",
      imgCard: "img-site",
      desc: `Um site simples, como uma pagina estática e responsivo consome em media 30 hora,
       enquanto algo mais complexo vai variar de 160 horas ou mais. 
       Assim fica claro que o levantamento detalhado dos requisitos é necessário 
       antes de qualquer orçamento.`,
      route: "Duvida09",
      category: ""
    },
    {
      title: "Quanto custa para desenvolver um software?",
      imgCard: "img-software",
      desc: `O desenvolvimento de um software seja ele um aplicativo, desktop ou web requer 
      um levantamento de requisitos. 
      O custo para fazer um termos de abertura de projeto pode variar de R$ 2400,00 a R$21000,00.
       E o custo do projeto final irá variar de R$ 12 mil a R$ 200 mil`,
      route: "Duvida10",
      category: ""
    }
  ]
};
