import Vue from "vue";
import Vuex from "vuex";
import api from "@/services";

// Modules
import Groups from "./modules/groups";
import Users from "./modules/users";
import Messages from "./modules/messages";
import Cards from "./modules/cards";
import Flags from "./modules/flags";
import Impacto from "./modules/solucoes";
import Questions from "./modules/faq";
import Stages from "./modules/stages";
import Images from "./modules/images";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || "{}"),
    accept: JSON.parse(localStorage.accept || "{}"),
    token: localStorage.token || undefined,
    card: Cards.state.cards,
    impacto: Impacto.state.impacto,
    questions: Questions.questions,
    stage: Stages.state.stages,
    flags: Flags.state.flags,
    allImages: Images.state,
    isLike: ""
  },
  getters: {
    userName: state => {
      return state.user.name === undefined ? "" : state.user.name;
    },
    isLogged: state =>
      Object.keys(state.user).length !== 0 && state.token !== undefined,
    tracking: state => {
      return state.accept === undefined ? "" : state.accept.tracking;
    },
    isAccept: state => {
      return state.accept === undefined ? false : state.accept.isAccept;
    },
    cards: state => {
      return state.card;
    },
    impacto: state => {
      return state.impacto;
    },
    questions: state => {
      return state.questions;
    },
    stages: state => {
      return state.stage;
    },
    flags: state => {
      return state.flags;
    },
    getAllImages: state => {
      return state.allImages;
    }
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    accept: (state, payload) => {
      state.accept = payload;
    },
    logout: state => {
      state.user = {};
      state.token = undefined;
    },
    filtering_category: (state, payload) => {
      state.card = payload;
    },
    filtering_alpha: (state, payload) => {
      state.card = payload;
    },
    filtering_date: (state, payload) => {
      state.card = payload;
    },
    filtering_relevance: (state, payload) => {
      state.card = payload;
    },
    show_cards: (state, payload) => {
      state.card = payload;
    },
    switch_country: (state, payload) => {
      state.flags = payload;
    },
    get_feedback: (state, payload) => {
      state.isLike = payload;
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user);
      localStorage.token = payload.token;
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
      context.commit("login", payload);
    },
    accept: (context, payload) => {
      localStorage.accept = JSON.stringify(payload);
      context.commit("accept", payload);
    },
    logout: context => {
      localStorage.user = {};
      localStorage.token = undefined;
      delete api.defaults.headers.common.Authorization;
      context.commit("logout");
    },
    filtering_category: (context, payload) => {
      context.commit("filtering_category", payload);
    },
    filtering_alpha: (context, payload) => {
      context.commit("filtering_alpha", payload);
    },
    filtering_date: (context, payload) => {
      context.commit("filtering_date", payload);
    },
    filtering_relevance: (context, payload) => {
      context.commit("filtering_relevance", payload);
    },
    show_cards: (context, payload) => {
      context.commit("show_cards", payload);
    },
    switch_country: (context, payload) => {
      context.commit("switch_country", payload);
    },
    get_feedback: (context, payload) => {
      context.commit("get_feedback", payload);
    }
  },
  modules: {
    // auth: auth,
    groups: Groups,
    users: Users,
    messages: Messages,
    cards: Cards,
    impacto: Impacto
  }
});
