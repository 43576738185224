export default {
  /** module que cuida das infos das flags do tradutor */
  namespaced: true,
  state: {
    flags: [
      { flag: "flag-br" },
      { flag: "flag-uk" },
      { flag: "flag-ger" },
      { flag: "flag-es" }
    ]
  }
};
