export default {
  namespaced: true,
  state: {
    stages: [
      {
        stageId: "briefing",
        titleImage: "gestao-image briefing",
        title: "Briefing",
        colMd: "6",
        icon: "icon icon-briefing-color-30",
        desc:
          "Momento em que nos conhecemos para entender o negócio e tirar dúvidas. É comum muitos clientes chegarem apenas com uma ideia, não tem problema, vamos juntos exercitar o CANVAS, 4EM e etc.",
        route: "#briefing",
        mais: "false"
      },
      {
        stageId: "planning",
        titleImage: "gestao-image planning",
        title: "Planning",
        colMd: "6",
        icon: "icon icon-planning-color-30",
        desc:
          "Agora vamos analisar os requisitos, avaliar o mercado e definir metas. A primeira pergunta que deve ser feita é se o projeto tem viabilidade. Para isso montamos a previsão do break even, calculando o VPL e TIR.",
        route: "#planning",
        mais: "false"
      },
      {
        stageId: "prototype",
        titleImage: "gestao-image prototype",
        title: "Prototype",
        colMd: "6",
        icon: "icon icon-prototype-color-30",
        desc:
          "Nesta estapa já fechamos um acordo inicial, e começamos os esquematics e mockups. A criatividade não tem freios nessa etapa, a ideia é documentar e elaborar o máximo possível a escalabilidade e variabilidade da solução.",
        route: "#prototype",
        mais: "false"
      },
      {
        stageId: "execution",
        titleImage: "gestao-image execution",
        title: "Execution",
        colMd: "6",
        icon: "icon icon-execution-color-30",
        desc:
          "Aprovados os modelos, começamos o  desenvolvimento da solução! Com entregas periódicas e incrementais, validamos e testamos junto ao cliente cada fase e módulo do projeto, assim garantimos a precisão Shinier!!!",
        route: "#execution",
        mais: "false"
      }
    ]
  }
};
